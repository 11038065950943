
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import AxiosAdapter from '@/request/AxiosAdapter'
import HeaderTit from '@/components/HeaderTit.vue'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'Order',
  components: { HeaderTit },
  setup() {
    const route = useRoute()
    const router = useRouter()
    // 获取用户信息
    const query: any = reactive({
      billId: route.query.billId,
      outTradeNo: route.query.outTradeNo
    })
    //支付方式
    const payList = reactive([
      {
        text: '选择支付方式',
        checkIcon: '',
        ischecked: true
      },
      {
        id: '1',
        text: '微信支付',
        checkIcon: 'el-icon-success',
        ischecked: false
      },
      {
        id: '2',
        text: '支付宝支付',
        checkIcon: 'el-icon-success',
        ischecked: false
      }
    ])
    const WeChat = ref()
    //选择微信浏览器的逻辑
    const isShowWechart = () => {
      payList.map((item: any) => {
        if (item.text == '微信支付') {
          WeChat.value = true
          return (item.ischecked = true)
        } else if (item.text == '支付宝支付') {
          return (item.ischecked = false)
        }
      })
    }
    //选择支付宝支付浏览器
    const isShowAlipay = () => {
      payList.map((item: any) => {
        if (item.text == '微信支付') {
          return (item.ischecked = false)
        } else if (item.text == '支付宝支付') {
          WeChat.value = false
          return (item.ischecked = true)
        }
      })
    }

    //用户信息默认值
    const orderList: any = reactive({
      id: '',
      medCaseId: '',
      medCaseUserId: '',
      medCaseNumberWord: '',
      organizationName: '',
      mediatorName: '',
      mediationFee: 0.1,
      courtName: '',
      paymentName: '',
      paymentDate: '',
      payStatusName: '',
      billStatus: '',
      businessId: '',
      outTradeNo: '',
      appid: '',
      aliAppid: ''
    })
    //获取支付列表
    const getList = async () => {
      const params = {
        billId: query.billId,
        outTradeNo: query.outTradeNo
      }
      const data: any = await AxiosAdapter.get({
        url: 'outInterface/getMedPayInfo',
        data: params
      })
      if ( data.paymentDate != null && data.paymentDate != '') {
        if (WeChat.value == true) {
          router.push({
            path: '/paymentsuccess',
            query: params
          })
        } else {
          router.push({
            path: '/alipayment',
            query: params
          })
        }
      } else {
        orderList.id = data.id
        orderList.medCaseId = data.medCaseId
        orderList.medCaseUserId = data.medCaseUserId
        orderList.medCaseNumberWord = data.medCaseNumberWord
        orderList.organizationName = data.organizationName
        orderList.mediatorName = data.mediatorName
        orderList.mediationFee = data.mediationFee
        orderList.courtName = data.courtName
        orderList.paymentName = data.paymentName
        orderList.paymentDate = data.paymentDate
        orderList.payStatusName = data.payStatusName
        orderList.billStatus = data.billStatus
        orderList.businessId = data.businessId
        orderList.outTradeNo = data.outTradeNo
        orderList.appid = data.appid
        orderList.aliAppid = data.aliAppid
      }
    }
    // 判断微信扫码支付宝扫码或者其他浏览器扫码
    function isWechat() {
      const ua = navigator.userAgent.toLowerCase()
      let flag = false
      if (ua.indexOf('micromessenger') > -1) {
        flag = true
        getList()
        isShowWechart()
      } else if (ua.indexOf('alipayclient') > -1) {
        flag = true
        getList()
        isShowAlipay()
      } else {
        flag = false
        return
      }
      return flag
    }
    //点击支付
    const handlerPay = (WeChat: boolean) => {
      if (WeChat == true) {
        const url = encodeURIComponent(
          `${window.location.origin}/pay/#/pay?amount=${orderList.mediationFee}&medCaseId=${orderList.medCaseId}&medCaseUserId=${orderList.medCaseUserId}&outTradeNo=${orderList.outTradeNo}&appid=${orderList.appid}&billId=${query}`
        )
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${orderList.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        const query = {
          caseId: orderList.medCaseId,
          caseUserId: orderList.medCaseUserId,
          outTradeNo: orderList.outTradeNo,
          amount: orderList.mediationFee,
          appId: orderList.aliAppid,
          subject: '测试'
        }
        router.push({
          path: '/alipay',
          query
        })
      }
    }
    onMounted(() => {
      isWechat()
      getList()
    })
    return {
      orderList,
      payList,
      getList,
      handlerPay,
      isShowWechart,
      isWechat,
      isShowAlipay,
      WeChat
    }
  }
})
